import { NTAQuestionType } from '@/models/NTAQuestionType.js';

export class NTACartItem {
    constructor(options) {
        this.uuid = crypto.randomUUID() // This is used to idenfity this item with all it's configurations
        this.available = true

        for (const index in options) {
            this[index] = options[index]
        }

        this.formattedQuestionsAnswers = []
        if (this.questionAnswers) {
            Object.keys(this.questionAnswers).forEach ( (key) => {
                this.formattedQuestionsAnswers.push(this.questionAnswers[key])
            })
        }

        this.formatProductBuilder()
        this.calculateTotalPrice()

    }

    setAvailibility(value) {
        this.available = value
    }

    appendData(productItem) {
        this.quantity += productItem.quantity
        this.calculateTotalPrice()
    }

    formatProductBuilder() {
        this.formattedProductBuilder = {}
        if (!this.questionAnswers) return

        Object.keys(this.questionAnswers).forEach( (key) => {
            if (this.questionAnswers[key].questionType === NTAQuestionType.Checkbox) {
                this.formattedProductBuilder[key] = {}
                this.questionAnswers[key].answers.forEach( (checkBoxAnswer) => {
                    this.formattedProductBuilder[key][checkBoxAnswer.answerIndex] = checkBoxAnswer.quantity
                })
            } 
            else if (this.questionAnswers[key].questionType === NTAQuestionType.Radio) {
                let answerIndex = this.questionAnswers[key].answerIndex
                this.formattedProductBuilder[key] = {}
                this.formattedProductBuilder[key][answerIndex] = 1 // quantity
            } 
            else if (this.questionAnswers[key].questionType === NTAQuestionType.Text) {
                this.formattedProductBuilder[key] = { 0: this.questionAnswers[key].answer }
            }
        })
        // console.log('formatted product builder=', this.formattedProductBuilder)
    }

    getDiscountPrice(price) {
        if (this.product.discount_percentage > 0) {
            return parseFloat(price) - ((parseFloat(price) * parseFloat(this.product.discount_percentage)) / 100);
        }
        return parseFloat(price);
    }

    calculateTotalPrice() {
        let basePrice = this.getDiscountPrice(parseFloat(this.product.price))
        if (this.variant) {
            basePrice = this.getDiscountPrice(parseFloat(this.variant.price))
        }

        this.formattedQuestionsAnswers.forEach( (answer) => {
            if (answer.questionType === NTAQuestionType.Radio) {
                basePrice += this.getDiscountPrice(parseFloat(answer.answer.price))
            } else if (answer.questionType === NTAQuestionType.Checkbox) {
                answer.answers.forEach ( (checkboxAnswer) => {
                    basePrice += this.getDiscountPrice(parseFloat(checkboxAnswer.price)) * parseFloat(checkboxAnswer.quantity)
                })
            }
        })

        let extraIngredientsPriceSum = 0
        for (let i in this.extraIngredients) {
            const formatedPrice = this.getDiscountPrice(this.extraIngredients[i].price)
            extraIngredientsPriceSum += formatedPrice * this.extraIngredients[i].quantity * this.quantity
        }

        this.totalPrice = basePrice * this.quantity + extraIngredientsPriceSum
    }

    getFormatedProduct() {
        return {
            type: 'product',
            product_id: parseInt(this.product.id),
            quantity: parseInt(this.quantity),
            optout: [],
            extra_ingredients: {},
            product_builder: {},
            alert: false
        }
    }

    isEqual(other) {
        if (!other instanceof NTACartItem) {
            return false
        }

        const sameProduct = (other) => {
            return other.product.id == this.product.id
        } 

        const sameVariant = (other) => {
            if (
                (!other.variant && !this.variant) ||
                (other.variant && this.variant && other.variant.id == this.variant.id)
                )
            {
                return true
            } else {
                return false
            }
        }

        const sameQuestionsAnswers = (other) => {
            const thisQuestionAnswersKeys = this.questionAnswers ? Object.keys(this.questionAnswers) : 0
            const otherQuestionAnswersKeys = other.questionAnswers ? Object.keys(other.questionAnswers) : 0

            if (otherQuestionAnswersKeys.length != thisQuestionAnswersKeys.length) {
                return false
            }

            for (let index = 0; index < thisQuestionAnswersKeys.length; index++) {
                const thisKey = thisQuestionAnswersKeys[index]
                const otherKey = otherQuestionAnswersKeys[index]

                const questionType = other.questionAnswers[otherKey].questionType
                if (questionType === NTAQuestionType.Checkbox) {
                    if (other.questionAnswers[otherKey].answers.length != this.questionAnswers[thisKey].answers.length) {
                        return false
                    }

                    let foundDifference = false
                    other.questionAnswers[otherKey].answers.some ( (otherCheckboxAnswer) => {
                        const result = this.questionAnswers[thisKey].answers.find ( item => 
                            item.uuid === otherCheckboxAnswer.uuid && item.quantity === otherCheckboxAnswer.quantity
                        )
                        if (result === undefined) {
                            foundDifference = true
                            return
                        }
                    } )

                    if (foundDifference) {
                        return false
                    }


                } else if (questionType === NTAQuestionType.Radio) {
                    if (other.questionAnswers[otherKey].answer.uuid !== this.questionAnswers[thisKey].answer.uuid) {
                        return false
                    }
                } else if (questionType === NTAQuestionType.Text) {
                    if (other.questionAnswers[otherKey].answer !== this.questionAnswers[thisKey].answer) {
                        return false
                    }
                }
            }
            return true
        }

        const sameIngredientsToRemove = (other) => {
            if (other.ingredientToRemove.length != this.ingredientToRemove.length) {
                return false
            }

            // Checking if all ingredients to remove are the same
            let thisIds = new Set(this.ingredientToRemove.map(item => item.id))
            for (let i in other.ingredientToRemove) {
                if (!thisIds.has(other.ingredientToRemove[i].id)) {
                    return false
                }
            }
            return true
        }

        const sameExtraIngredients = (other) => {
            if (other.extraIngredients.length != this.extraIngredients.length) {
                return false
            }

            // Checking if extra ingredients are the same with same quantities
            const thisIds = new Set()
            const thisQuantities = {}
            for (let i in this.extraIngredients) {
                thisIds.add(this.extraIngredients[i].id)
                thisQuantities[this.extraIngredients[i].id] = this.extraIngredients[i].quantity
            }
            for (let i in other.extraIngredients) {
                if (thisIds.has(other.extraIngredients[i].id)) {
                    if (thisQuantities[other.extraIngredients[i].id] != other.extraIngredients[i].quantity) {
                        return false
                    }
                } else {
                    return false
                }
            }
            return true
        }

        // console.log('same variant=', sameVariant(other))
        // console.log('same questions answers=', sameQuestionsAnswers(other))
        // console.log('same ingredients to remove=', sameIngredientsToRemove(other))
        // console.log('same extra ingredients=', sameExtraIngredients(other))

        return sameProduct(other) && sameVariant(other) && sameQuestionsAnswers(other) && sameIngredientsToRemove(other) && sameExtraIngredients(other)
    }
}
